<!-- users -->
<template>
  <GgPage pageType="2" title="Users">
    <template v-slot:left>
      <div class="mar-l-b">
        <gg-create-button @click="openDialogAdd(null)" label="Add user" />
      </div>
      <gg-flex-menus
        :menus="menuDatas"
        @select="menuSelect"
        :default-active="activeIndex"
      />
    </template>
    <!-- userslist -->
    <edoovo-table
      height="100%"
      :ref="usersData.ref"
      :data="usersData.tableData"
      rowKey="id"
      title="Users"
      defaultValue="-"
      class="list-tableBox"
      v-loading="usersData.isLoading"
      @sortChange="_onSortChangeUser"
      :sort="usersData.sort"
    >
      <!-- ===== -->
      <!-- 空状态 -->
      <!-- ===== -->
      <div slot="empty" style="text-align: center">
        <div
          style="
            width: 213px;
            text-align: center;
            border-radius: 50%;
            margin-bottom: 12px;
            line-height: 200px;
          "
        >
          <img
            style="width: 100%"
            :src="require('@/assets/images/products/noData.svg')"
          />
        </div>
        <p style="font-size: 22px">No users yet.</p>
      </div>
      <!-- ======= -->
      <!-- 分页组件 -->
      <!-- ======= -->
      <edoovo-pagination
        slot="pagination"
        @size-change="_pageSizeChange"
        @current-change="_pageChange"
        :current-page="query.current"
        :page-size="query.size"
        :total="usersData.totalNum"
      />
      <!-- ====== -->
      <!-- 筛选组件 -->
      <!-- ====== -->
      <div v-if="filterRenderData.length" class="list-filterBox" slot="filter">
        <base-filter
          ref="tablefilter"
          @filterCallback="_filterCallback"
          :renderData="filterRenderData"
          :multKeys="multKeys"
        />
      </div>
      <!-- =========== -->
      <!-- 每一项column -->
      <!-- =========== -->
      <edoovo-table-column
        size="L"
        label="Name"
        class="user_name"
        prop="name"
        sortName="firstName"
        :tooltip="true"
        :getTooltipText="(row) => `${row.firstName} ${row.lastName}`"
        v-slot="{ row }"
        @click="toUserInfo"
      >
        <div class="table-cell-box">
          <head-img
            class="table-cell-img"
            :src="row.photo"
            :firstName="row.firstName"
            :lastName="row.lastName"
            line-height="32"
          />
          <span class="table-cell-name"
            >{{ row.firstName }} {{ row.lastName }}</span
          >
        </div>
      </edoovo-table-column>
      <edoovo-table-column
        size="S"
        label="Gender"
        prop="gender"
        sortName="gender"
      />
      <edoovo-table-column
        size="M"
        label="Nationality"
        prop="nationality"
        sortName="nationality"
      />
      <edoovo-table-column
        size="L"
        label="Email"
        prop="account"
        sortName="account"
      />
      <!-- `${row.tel.countryCode ? row.tel.countryCode : ''}${
              row.tel.phoneNumber ? row.tel.countryCode : ''
            }` -->
      <edoovo-table-column
        size="M"
        label="Phone"
        prop="phone"
        v-slot="{ row }"
        :getTooltipText="(row) => `${row.countryCode}${row.phoneNumber}`"
      >
        {{ row.countryCode }}{{ row.phoneNumber }}
      </edoovo-table-column>
      <edoovo-table-column
        size="M"
        label="Roles"
        prop="roleName"
        sortName="name"
      />
      <edoovo-table-column size="M" label="Job title" prop="jobTitle" />
      <edoovo-table-column size="S" label="Type" prop="workType" />
      <!-- <edoovo-table-column size="S" label="Status" prop="status" /> -->
	  <edoovo-table-column size="S" label="Status" prop="status"
	  v-slot="{ row }"
	  :tooltip="true"
	   :getTooltipText="(row) => `${(row.status?row.status[0].toUpperCase()+row.status.substring(1):'-')}`"
	  >
	  		  {{(row.status?row.status[0].toUpperCase()+row.status.substring(1):'-')}}
	  </edoovo-table-column>
	  
	  
      <!-- ====== -->
      <!-- action -->
      <!-- ====== -->
      <edoovo-table-column v-slot="{ row }" key="action">
        <template v-if="row.status === 'active'">
          <el-tooltip
            content="Edit the user"
            popper-class="edoovo-table-cell-tooltip"
            :visible-arrow="false"
          >
            <gg-submit-button @click="openDialogAdd(row)">
              Edit
            </gg-submit-button>
          </el-tooltip>
          <el-tooltip
            content="Delete the user"
            popper-class="edoovo-table-cell-tooltip"
            :visible-arrow="false"
          >
            <div style="margin-left: 10px">
              <gg-submit-button @click="handleDelete(row.id)">
                Delete
              </gg-submit-button>
            </div>
          </el-tooltip>
        </template>
        <el-tooltip
          v-if="row.status === 'delete'"
          content="Reactivate the user"
          popper-class="edoovo-table-cell-tooltip"
          :visible-arrow="false"
        >
          <gg-submit-button @click="handleReactive(row.id)">
            Reactivate
          </gg-submit-button>
        </el-tooltip>
      </edoovo-table-column>
    </edoovo-table>
    <!-- adduser -->
    <BaseFormDialog1
      v-model="formDialogAdd.visible"
	  v-if="formDialogAdd.visible"
      :title="formDialogAdd.title"
      :renderPass="formDialogAdd.renderPass"
      :data="formDialogAdd.dataList"
      :ref="formDialogAdd.ref"
      textReminder="* indicates a required field"
    >
      <template slot="renderHtml" slot-scope="scope">
        <div v-if="scope.data.key === 'photo'" class="upload-imgbox">
          <div
            class="upload-img"
            @click="isImgCutShow = true"
            :ref="scope.data.key"
          >
            <head-img
              v-if="formDialogAdd.dataRender.photo"
              :src="formDialogAdd.dataRender.photo"
              line-height="88"
            />
            <i v-else class="material-icons iconfont iconadd_a_photo" />
          </div>
        </div>
        <baseGGSelect
          v-else-if="scope.data.key == 'gender'"
          v-model="formDialogAdd.dataRender.gender"
          :ref="scope.data.key"
          :placeholder="scope.data.placeholder"
          :class="{ float: scope.data.float }"
          :error="scope.data.error"
        >
          <md-option
            v-for="(item, index) in scope.data.genderList"
            :key="index"
            :value="item"
          >
            {{ item }}
          </md-option>
        </baseGGSelect>
        <baseGGSelect
          v-else-if="scope.data.key == 'nationality'"
          v-model="formDialogAdd.dataRender.nationality"
          :ref="scope.data.key"
          :placeholder="scope.data.placeholder"
          :class="{ float: scope.data.float }"
        >
          <md-option
            v-for="(item, index) in scope.data.itemList"
            :key="index"
            :value="item"
          >
            {{ item }}
          </md-option>
        </baseGGSelect>
        <gg-input
          v-else-if="scope.data.key == 'account'"
          :ref="scope.data.key"
          v-model="formDialogAdd.dataRender.account"
          :placeholder="scope.data.placeholder"
          :error="formDialogAdd.accountErrorObj"
        />
        <PhoneNumber
          v-else-if="scope.data.key == 'tel'"
          v-model="formDialogAdd.dataRender.tel"
          :ref="scope.data.key"
        ></PhoneNumber>
        <baseGGSelect
          v-else-if="scope.data.key == 'jobTitle'"
          v-model="formDialogAdd.dataRender.jobTitle"
          :ref="scope.data.key"
          :placeholder="scope.data.placeholder"
          :class="{ float: scope.data.float }"
        >
          <md-option
            v-for="(item, index) in scope.data.itemList"
            :key="index"
            :value="item"
          >
            {{ item }}
          </md-option>
        </baseGGSelect>
        <baseGGSelect
          v-else-if="scope.data.key == 'workType'"
          v-model="formDialogAdd.dataRender.workType"
          :ref="scope.data.key"
          :placeholder="scope.data.placeholder"
          :class="{ float: scope.data.float }"
        >
          <md-option
            v-for="(item, index) in scope.data.itemList"
            :key="index"
            :value="item"
          >
            {{ item }}
          </md-option>
        </baseGGSelect>
        <ResetPassword
          v-else-if="
            scope.data.key === 'password' &&
            formDialogAdd.title === formDialogAdd.add
          "
          switchTxt="Send password link"
          v-model="formDialogAdd.dataRender.password"
          :ref="scope.data.key"
          :autoSend="formDialogAdd.dataRender.sendLink"
          :autofocus="false"
          @sendEmail="sendEmail"
        ></ResetPassword>
      </template>
      <template slot="footer">
        <baseButton @click="handleAddCancel">Cancel</baseButton>
        <baseButton
          type="primary"
          :disabled="!formDialogAdd.isDisabled"
          @click="handleAddSubmit"
          >{{ formDialogAdd.submitBtnTxt }}</baseButton
        >
      </template>
    </BaseFormDialog1>
    <!-- 给创建的用户权限 -->
    <BaseFormDialog1
      v-model="formDialogAccess.visible"
      :title="formDialogAccess.title"
      :data="formDialogAccess.dataList"
      :renderPass="formDialogAccess.renderPass"
      :ref="formDialogAccess.ref"
      textReminder="* indicates a required field"
    >
      <template slot="renderHtml" slot-scope="scope">
        <div v-if="scope.data.key == 'adminConsole'">
          <div class="assigned-box">
            <div class="assigned-box-left">
              <base-img
                class="menuIcon"
                :src="
                  require(`@/assets/images/menu/${_getSvg(
                    'Admin Console'
                  )}.svg`)
                "
                alt
              />
              Admin console
            </div>
            <!-- @change="_changeSwitch" -->
            <div>
              <gg-switch
                v-model="scope.data.assigned"
                active-color="#1A73E8"
                inactive-color="#B4B4B4"
              />
              <div class="switch-txt">Assigned</div>
            </div>
          </div>
          <edoovo-table
            v-if="scope.data.assigned"
            height="auto"
            ref="assignedTable"
            :data="scope.data.tableData"
            rowKey="id"
            defaultValue="-"
            class="list-tableBox mar-t-20"
          >
            <edoovo-table-column
              size="L"
              label="Apps"
              class="user_name"
              prop="apps"
              :tooltip="false"
              v-slot="{ row }"
              @click="() => {}"
            >
              <div>
                <base-img class="table-cell-img" :src="row.avatar" />
                <span class="table-cell-name">{{ row.apps }}</span>
              </div>
            </edoovo-table-column>
            <edoovo-table-column
              size="S"
              :tooltip="false"
              label="Status"
              prop="status"
            />
          </edoovo-table>
        </div>
        <!-- :error="isError" -->
        <div v-else-if="scope.data.key == 'roleId'">
          <baseGGSelect
            v-model="formDialogAccess.dataRender.roleId"
            class="mar-t-20 mar-b-20"
            :placeholder="scope.data.placeholder"
            :ref="scope.data.key"
			@change='getSelectRole'
          >
            <md-option
              v-for="(item, index) in scope.data.roleList"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </md-option>
          </baseGGSelect>
          <edoovo-table
            v-if="selectedRole"
            height="auto"
            ref="appAccessTable"
            :data="scope.data.tableData"
            rowKey="id"
            defaultValue="-"
            class="list-tableBox mar-t-10 mar-b-20"
          >
            <edoovo-table-column
              size="M"
              label="Apps"
              class="user_name"
              prop="moduleName"
              :tooltip="false"
              v-slot="{ row }"
              @click="() => {}"
            >
              <div>
                <head-img
                  class="table-cell-img"
                  :src="
                    require(`@/assets/images/menu/${_getSvg(
                      row.moduleName
                    )}.svg`)
                  "
                  line-height="24"
                />
                <span class="table-cell-name">{{ row.moduleName }}</span>
              </div>
            </edoovo-table-column>
            <edoovo-table-column size="S" :tooltip="false" label="Status"
              >On</edoovo-table-column
            >
          </edoovo-table>
        </div>
      </template>
      <template slot="footer">
        <baseButton @click="handleAccessCancel">Cancel</baseButton>
        <baseButton
          type="primary"
          :disabled="!formDialogAccess.isDisabled"
          @click="handleAccessForm"
          >{{ formDialogAccess.submitBtnTxt }}</baseButton
        >
      </template>
    </BaseFormDialog1>
    <imgCutModal
      :visible.sync="isImgCutShow"
      v-if="isImgCutShow"
      @_uploadCallback="_uploadCallback"
    />
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />
  </GgPage>
</template>

<script>
import { Ajax } from "@/common";
import { mapState } from "vuex";
import { menuIconParams } from "@/common/baseData";
import { lightFormat } from "date-fns";
export default {
  name: "users",
  components: {},
  data() {
    return {
		selectedRole:"",
      // renderPass: true,
      menuDatas: [
        {
          name: "Users",
          key: "",
          active: true,
          children: [
            {
              name: "Active",
              key: "active",
            },
            {
              name: "Deleted",
              key: "delete",
            },
          ],
        },
        {
          name: "Roles",
          key: "Roles",
        },
      ],
      activeIndex: "",
      query: {
        size: 20,
        current: 1,
        status: "",
      },
      usersData: {
        ref: "usersTable",
        totalNum: 0,
        sort: { name: "", type: "" },
        isLoading: false,
        tableData: [],
      },
      filterRenderData: [
        { filterKey: "name", type: "input", title: "Name" },
        {
          filterKey: "gender",
          type: "radio",
          title: "Gender",
          radioData: [
            { key: "Male", value: "Male" },
            { key: "Female", value: "Female" },
          ],
        },
        {
          filterKey: "nationality",
          type: "checkbox",
          title: "Nationality",
          checkboxData: [],
        },
        { filterKey: "email", type: "input", title: "Email" },
        { filterKey: "phoneNumber", type: "input", title: "Phone No." },
        {
          filterKey: "jobTitle",
          type: "radio",
          title: "Job title",
          radioData: [
            { key: "Admin", value: "Admin" },
            { key: "Registrar", value: "Registrar" },
          ],
        },
        {
          filterKey: "workType",
          type: "radio",
          title: "Type",
          radioData: [],
        },
      ],
      formDialogAdd: {
        ref: "formDialogAdd",
        visible: false,
        title: "",
        add: "Add user",
        edit: "Edit user",
        submitBtnTxt: "Next",
        renderPass: false,
        slotData: ["gender", "nationality",  "account"],
        isDisabled: true,
        dataRender: {
          photo: "",
          account: "",
          gender: "",
          nationality: "",
          tel: {
            countryCode: "+65",
            phoneNumber: "",
          },
          jobTitle: "",
          workType: "",
          password: "",
          sendLink: false,
        },
        accountErrorObj: {
          show: false,
          message: "",
        },
        dataList: [
          {
            placeholder: "photo",
            key: "photo",
            value: "",
            renderHtml: true,
          },
          {
            placeholder: "First name *",
            key: "firstName",
            value: "",
            float: true,
            reg: /\S/,
            errorText: "First name is required",
          },
          {
            placeholder: "Last name *",
            key: "lastName",
            value: "",
            float: true,
            reg: /\S/,
            errorText: "Last name is required",
          },
          {
            placeholder: "Gender *",
            key: "gender",
            value: "",
            genderList: ["Male", "Female"],
            float: true,
            renderHtml: true,
          },
          {
            placeholder: "Nationality *",
            key: "nationality",
            value: "",
            itemList: [],
            float: true,
            renderHtml: true,
          },
          {
            placeholder: "Email *",
            key: "account",
            value: "",
            float: true,
            renderHtml: true,
          },
          {
            placeholder: "Phone number *",
            key: "tel",
            value: {
              countryCode: "+65",
              phoneNumber: "",
            },
            renderHtml: true,
          },
          {
            placeholder: "Job title",
            key: "jobTitle",
            value: "",
            itemList: [],
            float: true,
            renderHtml: true,
          },
          {
            placeholder: "Working type",
            key: "workType",
            value: "",
            itemList: [],
            float: true,
            renderHtml: true,
          },
          {
            placeholder: "Password *",
            key: "password",
            type: "password",
            value: "",
            sendLink: false,
            renderHtml: true,
          },
        ],
        formData: {},
      },
      multKeys: [],
      isImgCutShow: false,
      formDialogAccess: {
        ref: "formDialogAccess",
        visible: false,
        title: "",
        add: "Add user",
        edit: "Edit user",
        submitBtnTxt: "Create",
        renderPass: false,
        isDisabled: true,
        dataRender: {
          roleId: "",
          adminConsole: [
            { lable: "Users", value: "On" },
            { lable: "Settings", value: "On" },
          ],
        },
        dataList: [
          {
            key: "adminConsole",
            assigned: false,
            tableData: [
              {
                apps: "Users",
                avatar: require("@/assets/images/menu/users.svg"),
                status: "On",
              },
              {
                apps: "Settings",
                avatar: require("@/assets/images/menu/settings.svg"),
                status: "On",
              },
            ],
            renderHtml: true,
          },
          {
            placeholder: "Role*",
            key: "roleId",
            value: "",
            roleList: [],
            tableData: [],
            renderHtml: true,
          },
        ],
      },
      snackbarData: {
        visible: false,
        content: "",
      },
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.userId,
    }),
  },
  watch: {
    "formDialogAdd.dataRender.account": function (res) {
      let isPass = /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/.test(res);
      if (res.length === 0) {
        this.formDialogAdd.accountErrorObj = {
          show: true,
          message: "Email required",
        };
      } else if (!isPass) {
        this.formDialogAdd.accountErrorObj = {
          show: true,
          message: "Email address is invalid",
        };
      } else {
        this.formDialogAdd.accountErrorObj = {
          show: false,
          message: "",
        };
      }
    },
    formDialogAdd: {
      deep: true,
      handler(res) {
        this.$nextTick(() => {
          const isForm = this.$refs.formDialogAdd.valid(); // 表单其他非插槽元素是否验证通过
          let isAuto = false;
          const isRender = res.slotData.every(
            // 插槽数据是否通过校验
            (item) => this.$refs[item] && !!this.$refs[item].value
          );
          let isPhone = false; // phoneNumber是否通过校验

          if (this.$refs.password) {
            if (
              this.$refs.password.autoSend ||
              !this.$refs.password.isError.show
            ) {
              isAuto = true;
            }
          } else {
            isAuto = true;
          }

          if (this.$refs.tel) {
            isPhone =
              !!this.$refs.tel.value.phoneNumber &&
              !this.$refs.tel.isError.show;
          } else {
            isPhone = false;
          }

          res.renderPass = res.isDisabled =
            isRender &&
            isAuto &&
            isForm &&
           
            !this.$refs.account.error.show;
			// console.log(isRender)
			console.log(isAuto)
			console.log(isForm)
          console.log("renderPass:", this.formDialogAdd.renderPass);
        });
      },
    },
    formDialogAccess: {
      deep: true,
      handler(res) {
        if (!!res.dataRender.roleId) {
          res.renderPass = res.isDisabled = true;
        } else {
          res.renderPass = res.isDisabled = false;
        }
      },
    },
    selectedRole(roleId) {
      this.selectRoleById(roleId);
    },
  },
  methods: {
	  getSelectRole(){
		this.selectedRole=this.formDialogAccess.dataRender.roleId
	  },
    _getSvg(menu) {
      if (menuIconParams[menu] && menuIconParams[menu].svg) {
        return menuIconParams[menu].svg;
      }
      return "product_app";
    },
    getRoleList() {
      Ajax.get("/usermanage/role/getRoleByBelongId", {
        userId: this.userId,
      })
        .then((res) => {
          this.formDialogAccess.dataList.find(
            (i) => i.key === "roleId"
          ).roleList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    menuSelect(key, item) {
      this.activeIndex = key;
      this.query.status = item.key;
      this.getData();
      if (key === "Roles") {
        this.$router.push({ name: "userRoles" });
      }
    },
    toUserInfo(row) {
      this.$router.push({
        name: "usersInfo",
        query: { id: row.row.id },
      });
    },
    getData() {
      this.usersData.isLoading = true;
      Ajax.post("/usermanage/superAdmin/getAllUserOfSuper", this.query)
        .then((res) => {
          this.usersData.isLoading = false;
          let { records, total } = res.data;
          this.usersData.tableData = records;
          this.usersData.totalNum = total;
        })
        .catch((err) => {
          this.usersData.isLoading = false;
        });
    },
    _onSortChangeUser(sort) {
      this.usersData.sort = sort;
      this.query.sortName = sort.name;
      this.query.sortType = sort.type;
      this.getData();
    },
    _pageChange(val) {
      this.query.current = val;
      this.getData();
    },
    _pageSizeChange(val) {
      this.query.size = val;
      this.getData();
    },
    _filterCallback(data) {
      let obj = {};
      if (data.length == 0) {
        this.query = {
          size: 20,
          current: 1,
          status: "",
        };
      }

      data.filter((i) => {
        obj[i.filterKey] = i.filterVal;
      });

      this.query = {
        size: 20,
        current: 1,
        status: "",
      };
      this.query = Object.assign(obj, this.query);
      this.getData();
    },
    _uploadCallback(formData, fileData) {
      formData.append("multipartFile", fileData.raw);
      Ajax.post("/media/public/file/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          this.formDialogAdd.dataRender.photo = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
          this.isImgCutShow = false;
        })
        .catch(() => {
          this.isImgCutShow = false;
        });
    },
    handleSnack(content) {
      this.snackbarData.content = content;
      this.snackbarData.visible = true;
    },
    sendEmail(val) {
      this.formDialogAdd.dataRender.sendLink = val;
    },
    selectRoleById(roleId) {
      Ajax.get("/usermanage/role/selectRoleById", {
        roleId: roleId,
      })
        .then((res) => {
          this.formDialogAccess.dataList.find(
            (i) => i.key === "roleId"
          ).tableData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openDialogAdd(row) {
      if (row == null) {
        this.formDialogAdd.title = this.formDialogAdd.add;
        this.formDialogAdd.submitBtnTxt = "Next";
        this.formDialogAccess.title = this.formDialogAccess.add;
        this.formDialogAccess.submitBtnTxt = "Create";
        this.formDialogAdd.dataList.forEach((i) => {
          i.value = "";
        });

        this.formDialogAdd.dataRender = {
          photo: "",
          account: "",
          gender: "",
          nationality: "",
          tel: {
            countryCode: "+65",
            phoneNumber: "",
          },
          jobTitle: "",
          workType: "",
          password: "",
          sendLink: false,
        };

        this.formDialogAccess.dataRender.roleId = "";
        this.formDialogAccess.dataList.find(
          (item) => item.key === "adminConsole"
        ).assigned = false;
      } else {
        this.formDialogAdd.title = this.formDialogAdd.edit;
        this.formDialogAdd.submitBtnTxt = "Next";
        this.formDialogAdd.renderPass = false;
        this.formDialogAdd.id = row.id;
        this.formDialogAccess.title = this.formDialogAccess.edit;
        this.formDialogAccess.submitBtnTxt = "Save";
        this.formDialogAdd.dataList.forEach((i) => {
          if (row[i.key]) {
            i.value = row[i.key];
          }
        });

        Object.keys(this.formDialogAdd.dataRender).forEach((item) => {
          this.formDialogAdd.dataRender[item] = row[item];
        });

        this.formDialogAdd.dataRender.tel = {
          countryCode: row["countryCode"],
          phoneNumber: row["phoneNumber"],
        };

        Object.keys(this.formDialogAccess.dataRender).forEach((item) => {
          this.formDialogAccess.dataRender[item] = row[item];
        });

        this.formDialogAdd.dataRender.sendLink = true;

        this.formDialogAccess.dataList.forEach((item1) => {
          if (item1.key === "adminConsole") {
            if (JSON.parse(row.adminConsole).length === 0) {
              item1.assigned = false;
            } else {
              item1.assigned = true;
            }
          }
        });
      }
      this.formDialogAdd.visible = true;
    },
    fillData() {
      let data = {};
      const assigned = this.formDialogAccess.dataList.find(
        (item) => item.key === "adminConsole"
      ).assigned;
      if (!assigned) {
        this.formDialogAccess.dataRender.adminConsole = [];
      } else {
        this.formDialogAccess.dataRender.adminConsole = [
          { lable: "Users", value: "On" },
          { lable: "Settings", value: "On" },
        ];
      }
      data = Object.assign(
        {},
        this.formDialogAdd.formData,
        this.formDialogAccess.dataRender,
        {
          userId: this.userId,
        }
      );
      return data;
    },
    handleAddCancel() {
      this.formDialogAdd.visible = false;
    },
    handleAddSubmit() {
      let obj = {};
      this.$refs.formDialogAdd.dataExceptRender.forEach((item) => {
        obj[item.key] = item.value;
      });

      this.formDialogAdd.formData = Object.assign(
        {},
        obj,
        this.formDialogAdd.dataRender
      );

      this.formDialogAdd.formData.countryCode = this.formDialogAdd.dataRender.tel.countryCode;
      this.formDialogAdd.formData.phoneNumber = this.formDialogAdd.dataRender.tel.phoneNumber;
      this.formDialogAdd.formData.isAuto = this.formDialogAdd.dataRender
        .sendLink
        ? "1"
        : "0";
      // 邮箱唯一性校验
      let params = {};

      if (this.formDialogAdd.title === this.formDialogAdd.add) {
        params = {
          email: this.formDialogAdd.dataRender.account,
          type: 0,
        };
      } else {
        params = {
          email: this.formDialogAdd.dataRender.account,
          type: 1,
          userId: this.formDialogAdd.id,
        };
      }

      Ajax.post("/usermanage/email/isUsingEmail", params)
        .then((res) => {
          if (res.data) {
            this.formDialogAdd.accountErrorObj = {
              show: true,
              message: "Email already exists",
            };
          } else {
            this.formDialogAdd.visible = false;
            this.formDialogAccess.visible = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleAccessCancel() {
      this.formDialogAccess.visible = false;
    },
    handleAccessForm() {
      if (this.formDialogAccess.title === this.formDialogAccess.add) {
        this.handleAccessAddSubmit();
      } else {
        this.handleAccessEditSubmit();
      }
    },
    handleAccessAddSubmit() {
      Ajax.post("/usermanage/superAdmin/saveSuper", this.fillData())
        .then((res) => {
          if (res.code === "0000") {
            this.getData();
            this.handleSnack("User has been added");
            this.formDialogAccess.visible = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleAccessEditSubmit() {
      let params = {};

      params = Object.assign({}, this.fillData(), {
        id: this.formDialogAdd.id,
      });

      Ajax.post("/usermanage/superAdmin/updateSuperAdmin", params).then(
        (res) => {
          if (res.code === "0000") {
            this.getData();
            this.handleSnack("User has been edited");
            this.formDialogAccess.visible = false;
          }
        }
      );
    },
    handleReactive(id) {
      this.$confirm(
        "Once the user is reactivated, you will be able to recover the user data.",
        "Reactivate user ?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Reactivate",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false
        }
      )
        .then(() => {
          Ajax.post("/usermanage/superAdmin/updateStatus", {
            status: "active",
            userId: id,
          })
            .then((res) => {
              if (res.code === "0000") {
                this.getData();
                this.snackbarData = {
                  visible: true,
                  content: "User has been reactivated",
                };
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },
    handleDelete(id) {
      this.$confirm(
        "Once the user is deleted, the user will not be able to access the app.",
        "Delete user ?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Delete",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false
        }
      )
        .then(() => {
          Ajax.post("/usermanage/superAdmin/updateStatus", {
            status: "delete",
            userId: id,
          })
            .then((res) => {
              if (res.code === "0000") {
                this.getData();
                this.snackbarData = {
                  visible: true,
                  content: "User has been deleted",
                };
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },
    getSettingList(dataLevel, dataType) {
      let params = {
        dataLevel: dataLevel,
        dataType: dataType,
        userId: this.userId,
      };

      Ajax.post("/usermanage/setting/getList", params)
        .then((res) => {
          let arr = [];
          res.data.forEach((item1) => {
            this.filterRenderData.forEach((item) => {
              let obj = {};
              if (item1.dataType === item.filterKey) {
                obj = {
                  key: item1.value,
                  value: item1.value,
                };
                arr.push(obj);
              }
            });
            this.formDialogAdd.dataList.forEach((item2) => {
              if (item1.dataType === item2.key) {
                item2.itemList.push(item1.value);
              }
            });
          });

          this.filterRenderData.find(
            (item) => item.filterKey === dataType
          ).radioData = arr;

          this.filterRenderData.find(
            (item) => item.filterKey === dataType
          ).checkboxData = arr;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getData();
    this.getRoleList();
    this.getSettingList("user", "workType");
    this.getSettingList("user", "jobTitle");
    this.getSettingList("general", "nationality");
	// this.getSettingList("general", "gender");
  },
};
</script>
<style lang="less">
.assigned-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
  color: #000;
  &-left {
    display: flex;
    align-items: center;
    .menuIcon {
      margin-right: 8px;
      width: 32px;
    }
  }
}
</style>
<style lang="less" scoped>
.phone-number-content {
  margin-top: 15px;
  margin-bottom: 0;
  width: 100%;
  float: left;
}
.table-cell-img {
  width: 24px;
  height: 24px;
  /deep/ img {
    height: 100%;
    width: auto;
  }
}
</style>
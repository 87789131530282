<template>
  <div>
    <gg-submit-button @click="isShow6 = true"
      >自定义组合，表单和按钮</gg-submit-button
    >
    <BaseFormDialog1
      v-model="isShow6"
      title="Standard form"
      :renderPass="renderPass6"
      :data="data6"
      ref="form6"
      textReminder="* indicates a required field"
      userReminder="Maximum 8 students allowed for each class."
    >
      <template slot="renderHtml" slot-scope="scope">
        <template v-for="(item, index) in formData">
          <gg-input
            v-if="scope.data.key === item.key"
            v-model="item.value"
            :ref="item.key"
            :placeholder="item.key"
            :error="item.errorObj"
            :key="index"
          />
        </template>
      </template>
      <template slot="footer">
        <baseButton @click="isShow6 = false"></baseButton>
        <baseButton type="primary" :disabled="isDisabled6" @click="submit6"
          ></baseButton
        >
      </template>
    </BaseFormDialog1>
  </div>
</template>

<script>
import { lightFormat } from "date-fns";
export default {
  props: {},
  data() {
    return {
      isShow6: false,
      renderPass6: false,
      isDisabled6: false,
      data6: [
        {
          placeholder: "name *",
          key: "name",
          value: "",
          reg: /^[\S]{2,4}$/,
          errorText: "名字为2-4个字，不含有空格",
        },
        {
          placeholder: "gender *",
          key: "gender",
          value: "",
        },
        {
          placeholder: "email *",
          key: "email",
          value: "",
          renderHtml: true,
        },
        {
          placeholder: "nation *",
          key: "nation",
          value: "",
          renderHtml: true,
        },
        {
          placeholder: "country *",
          key: "country",
          value: "",
          renderHtml: true,
        },
      ],
      formData: [
        {
          key: "nation",
          value: "",
          errorObj: {
            show: false,
            message: "nation!!!",
          },
        },
        {
          key: "country",
          value: "2",
          errorObj: {
            show: false,
            message: "country error",
          },
        },
      ],
    };
  },
  watch: {},
  methods: {
    submit6() {
      // 表单其他非插槽元素是否验证通过
      const formPass = this.$refs.form6.valid();
      // 表单元素输入结果数据
      const formData = this.$refs.form6.dataExceptRender;
      console.log("其他元素是否验证通过", formPass);
      console.log("表单数据", formData);
      // 此处将email input 初始化参数置为false，错误提示才会显示
      console.log(this.$refs, "lll");
      this.$refs.nation[0].init = false;
      // 插槽表单元素数据需要自己处理
      if (!this.formData[0].value) {
        this.formData[0].errorObj = {
          show: true,
          message: "自定义没值",
        };
        return;
      }
      this.formData[0].errorObj = {
        show: true,
        message: "重复了",
      };
    },
  },
};
</script>